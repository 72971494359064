import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { AnimatedFintech } from 'components/animated-fintech';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { PATHS } from 'constants/paths';
import { MainSection } from 'modules/main-section';

const SPrefixLangLink = styled(PrefixLangLink)`
    color: inherit;
    text-decoration: underline;
`;

export const FintechMainSection = () => {
    const { formatMessage } = useIntl();

    return (
        <MainSection
            icon={<AnimatedFintech />}
            iconWrapperCss={css`
                max-width: 33.125rem;
            `}
            titleId="fintech-page.topHeadline"
            paragraphs={[
                formatMessage(
                    {
                        id: 'fintech-page.topParagraph.0',
                    },
                    {
                        link: (chunks) => {
                            return (
                                <SPrefixLangLink to={`${PATHS.EXPERTISES}/nft`}>
                                    {chunks[0]}
                                </SPrefixLangLink>
                            );
                        },
                    },
                ),
                formatMessage(
                    {
                        id: 'fintech-page.topParagraph.1',
                    },
                    {
                        link: (chunks) => {
                            return (
                                <SPrefixLangLink
                                    to={`${PATHS.BLOG}/open-api-banking`}
                                >
                                    {chunks[0]}
                                </SPrefixLangLink>
                            );
                        },
                    },
                ),
            ]}
            estimateProjectAnalytics={
                gaTrackedEvents.FINTECH.CTA.ESTIMATE_PROJECT
            }
            intoCallAnalytics={gaTrackedEvents.FINTECH.CTA.INTRO_CALL}
            isParagraphFormattedPreviously
        />
    );
};
