import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { HeaderThird } from 'components/header-third';
import { Paragraph } from 'components/paragraph';
import { SectionTiles } from 'components/section-tiles';
import { useTranslatedTitle } from 'hooks/use-translated-title';

const STextWrapper = styled.div`
    text-align: center;
    margin: 0 auto 3.75rem;
    ${({ textWrapperCss }) => textWrapperCss}
`;

const SHeaderSecond = styled(HeaderSecond)`
    ${({ titleCss }) => titleCss}
`;

const SParagraph = styled(Paragraph)`
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
`;

export const WhereNeedSection = () => {
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(
        'fintech-page.whereCustomFintech.title',
    );

    return (
        <Container id={kebabCaseId}>
            <STextWrapper>
                <SHeaderSecond id={kebabCaseId} handleCopyUrl={handleCopyUrl}>
                    <FormattedMessage id="fintech-page.whereCustomFintech.title" />
                </SHeaderSecond>
            </STextWrapper>
            <HeaderThird id="fintech-page.whereCustomFintech.firstSubheading">
                <FormattedMessage id="fintech-page.whereCustomFintech.firstSubheading" />
            </HeaderThird>
            <SParagraph>
                <FormattedMessage id="fintech-page.whereCustomFintech.firstParagraph" />
            </SParagraph>
            <HeaderThird id="fintech-page.whereCustomFintech.secondSubheading">
                <FormattedMessage id="fintech-page.whereCustomFintech.secondSubheading" />
            </HeaderThird>
            <SParagraph>
                <FormattedMessage id="fintech-page.whereCustomFintech.secondParagraph" />
            </SParagraph>
            <HeaderThird id="fintech-page.whereCustomFintech.thirdSubheading">
                <FormattedMessage id="fintech-page.whereCustomFintech.thirdSubheading" />
            </HeaderThird>
            <SParagraph>
                <FormattedMessage id="fintech-page.whereCustomFintech.thirdParagraph" />
            </SParagraph>
            <HeaderThird id="fintech-page.whereCustomFintech.fourthSubheading">
                <FormattedMessage id="fintech-page.whereCustomFintech.fourthSubheading" />
            </HeaderThird>
            <SParagraph>
                <FormattedMessage id="fintech-page.whereCustomFintech.fourthParagraph" />
            </SParagraph>
        </Container>
    );
};
